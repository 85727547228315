import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/mountain_dan.png";
import HeaderSocial from "./HeaderSocials";
import Typewriter from "typewriter-effect";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Daniel Morales</h1>
        <Typewriter
          options={{
            strings: [
              "Full Stack Developer",
              "Solutions Architect",
              "Data Scientist",
              "Network Engineer"
            ],
            autoStart: true,
            loop: true,
            wrapperClassName: "type-effect",
            delay: 80,
            deleteSpeed: 30,
            pauseFor: 500
          }}
        />
        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
