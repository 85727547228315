import React from "react";
import "./about.css";
import IMG1 from "../../assets/IMG_3269.jpg";
import { FaAward } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";
import { FiUsers } from "react-icons/fi";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={IMG1} alt="me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years working</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>10+ Clients</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ completed projects</small>
            </article>
          </div>

          <p>
            Hello! I'm Danny,
            <br></br>
            <br></br>
            Software Engineer, Network Engineer, Data Scientist, Blockchain
            Enthusiast, Traveler and Man of culture.
            <br></br>
            <br></br>A natural affinity for computers has sparked a pursuit into
            the tech industry with aspirations of creating applications and
            software to help further the advancement of innovation in
            technology.
            <br></br>
            <br></br>I completed an Associate of Arts and an Associate of
            Science in Enterprise Cloud Computing at Miami Dade College,
            followed by a Bachelor of Science in Computer Science at Florida
            International University. I have a solid academic foundation in
            computer science, with course knowledge in operating systems, data
            structures, and systems programming, complemented by my degrees in
            Enterprise Cloud Computing and Computer Science.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Chat!
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
