import React from "react";
import "./portfolio.css";
import CLIPPY from "../../assets/clippy.png";
import FUTBOL from "../../assets/futbol_python.png"

const data = [
  {
    id: 2,
    image: CLIPPY,
    title: "Clippy Reborn",
    github: "https://github.com/damora-code/clippy-reborn",
    demo: "http://www.daniel-morales.site/",
  },{
    id: 3,
    image: FUTBOL,
    title: "Futbol Permutations",
    github: "https://github.com/damora-code/permutation-challenge",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="__blank">
                  Github
                </a>
                
              </div>
            </article>
            
            
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
