import React from "react";
import "./services.css";
import { HiOutlineCheck } from "react-icons/hi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Full Stack Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Software development from conception to deployment</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Collaborated in Agile teams for data-driven decisions.</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Design client-side and server-side architecture</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Troubleshoot, debug and upgrade software</p>
            </li>

            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Applications with mobile responsive design</p>
            </li>
          </ul>
        </article>
        {/* END OF FULL STACK DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Data Analysis</h3>
          </div>
          <ul className="service__list">
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Processed large datasets in Excel for efficiency.</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Explored complex datasets using Snowflake operations.</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Analyzed data with Python, Pandas, and NumPy.</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Visualized insights using Matplotlib for clarity.</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Supported sports event data integration and reporting.</p>
            </li>
          </ul>
        </article>
        {/* END OF BLOCKCHAIN DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Networking</h3>
          </div>
          <ul className="service__list">
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Manage, service and maintain multiple servers</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Troubleshoot and resolve networking or security issues</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Configure and install various network devices</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Monitor performance, system availability and reliability</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Create effective redunancy in data storage</p>
            </li>
          </ul>
        </article>
        {/* END OF NETWORKING */}
      </div>
    </section>
  );
};

export default Services;
